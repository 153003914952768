require('@fortawesome/fontawesome-free');
require('@fortawesome/fontawesome-free/css/all.css');
require('jquery');
require('popper.js');
require('bootstrap');
require('in-view');
require('sticky-kit/dist/sticky-kit');
require('svg-injector');
require('jquery.scrollbar');
require('jquery-scroll-lock');
require('imagesloaded');

require('./theme.scss');
require('swiper');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('@fancyapps/fancybox/dist/jquery.fancybox.min');
require('./js/purpose/libs/swiper');
